<template>
  <el-dialog v-model="dialogVisible" title="編輯角色名稱" width="650px">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="search-form"
    >
      <el-form-item class="form-item" prop="role_name">
        <template #label>
          <span class="label">角色名稱</span>
        </template>
        <el-input
          v-model="ruleForm.role_name"
          :value="ruleForm.role_name"
          placeholder="請輸入角色名稱"
          style="width: 100%"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleSave(ruleFormRef)" :disabled="!checkSubmit()" style="margin-left: 25px">
          儲存
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref, reactive } from 'vue';
import {
  useGetters,
  // useActions,
  useMutations,
} from '@/utils/mapStore';
import { useRouter } from 'vue-router';
export default {
  name: 'account-dialog',
  setup() {
    const getPermissionDateil = useGetters('permissionManagement', ['getPermissionDateil']);
    const SET_ROLE_NAME = useMutations('permissionManagement', ['SET_ROLE_NAME']);
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);

    if (dialogVisible) {
      ruleForm.value = {
        role_name: '',
      };
    }

    //驗證與檢查-----------------------------------------------------------------
    const rules = reactive({
      role_name: [{ required: true, message: '請輸入角色名稱', trigger: 'blur' }],
    });

    //儲存
    const router = useRouter();
    const handleSave = async (form) => {
      try {
        const roleName = await form.validate();
        if (!roleName) return;
        SET_ROLE_NAME(ruleForm.value.role_name);
        router.push('/account/permission/detail');
        close();
      } catch (e) {
        e;
      }
    };

    const checkSubmit = () => {
      let isValid;
      //未選擇任一條件
      isValid = !Object.keys(ruleForm.value).every((e) => !ruleForm.value[e] || ruleForm.value[e].length === 0);
      return isValid;
    };
    return {
      dialogVisible,
      close,
      cusProps,
      ruleFormRef,
      ruleForm,
      rules,
      handleSave,
      checkSubmit,
      getPermissionDateil,
    };
  },
};
</script>
<style lang="scss" scoped>
.search-form {
  width: 100%;
}
.form-item {
  @include basic-form-item;
}
.dialog-footer {
  @include full-button;
}
</style>
